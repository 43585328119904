<template>
  <router-link v-if="type === 'internal'" :to="to" class="link">
    <slot></slot>
  </router-link>

  <a v-else :href="to" target="_blank" rel="noopener noreferrer">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'Link',

  props: {
    to: {
      required: true,
      type: String,
    },

    type: {
      default: 'internal',
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  color: var(--main-color-500);
  font-weight: var(--medium);

  &:hover {
    text-decoration: underline;
  }
}
</style>
