<template>
  <div v-if="hasFinished" class="valuation">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[]"
          :currentView="{ label: 'Valorización de Puestos', icon: 'suitcase' }"
        />
        <h2>Valorizaci&oacute;n</h2>
      </div>
      <div class="content__buttons">
        <Button to="valorizacion/sesgo">
          Ver Sesgo
          <unicon name="file" fill="currentColor" />
        </Button>
        <Button to="valorizacion/mapa">
          Ver Resultados
          <unicon name="file" fill="currentColor" />
        </Button>
      </div>
    </div>

    <div class="filters__section">
      <Menu direction="below" :closeOnItemClick="false">
        <template #label>
          <filter-item
            :filter="{
              id: 'periods',
              name: 'Periodo actual',
              type: 'select',
              options: createdPeriods,
            }"
          />
        </template>
        <template #options>
          <menu-item
            v-for="period in createdPeriods"
            @click="onPeriodSelect(period)"
            :key="period.id"
          >
            {{ period.name }}
            <unicon v-if="period.active" width="16px" height="16px" name="check" fill=""></unicon>
          </menu-item>
        </template>
      </Menu>

      <filters :filters="filters" :filtersOptions="filtersOptions" @filter="onFilter" />
    </div>

    <div class="content" v-if="dataIsLoaded">
      <div class="action-bar">
        <span class="stat">
          <span>Total:</span>
          <span>{{ totalPositions || 0 }}</span>
        </span>

        <span class="stat">
          <span>Valorizados:</span>
          <span>{{ positionsEvaluated || 0 }}</span>
        </span>

        <span class="stat">
          <span>Pendientes:</span>
          <span>{{ totalPositions - positionsEvaluated }}</span>
        </span>
      </div>

      <EvaluationTable
        :filters="appliedFilters"
        @startEvaluation="startEvaluation"
        @viewEvaluationReport="(position) => $refs.summaryViewer.viewEvaluationReport(position)"
      />
    </div>

    <LoadingScreen v-else />

    <JobEvaluationModal
      :isOpen="isJobEvaluationModalOpen"
      :position="positionToEvaluate"
      @close="closeEvaluation"
    />

    <PositionEvaluationSummaryTable ref="summaryViewer" />
  </div>

  <div v-else class="not-finished">
    <unicon name="ban" class="block-icon" fill="currentColor" width="80px" height="80px" />
    <h2>Debe terminar la puntuación primero</h2>
    <Link to="puntuacion">
      Ir a puntuación
      <unicon name="arrow-right" fill="currentColor" />
    </Link>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

import Button from '@/components/buttons/Button.vue';
import Filters from '@/components/filters/Filters.vue';
import Link from '@/components/Link.vue';
import LoadingScreen from '@/components/loading/LoadingScreen.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import FilterItem from '@/components/filters/FilterItem.vue';
import PositionEvaluationSummaryTable from './PositionEvaluationSummaryTable.vue';
import EvaluationTable from './EvaluationTable.vue';
import JobEvaluationModal from './JobEvaluationModal.vue';

export default {
  components: {
    Button,
    EvaluationTable,
    Filters,
    JobEvaluationModal,
    Link,
    LoadingScreen,
    PositionEvaluationSummaryTable,
    Breadcrumbs,
    Menu,
    MenuItem,
    FilterItem,
  },

  data: () => ({
    isJobEvaluationModalOpen: false,
    positionToEvaluate: {},
    filters: [
      {
        id: 'levels',
        name: 'Niveles',
        type: 'text',
      },
      {
        id: 'management',
        name: 'Gerencia',
        type: 'select',
      },
      {
        id: 'area',
        name: 'Área',
        type: 'select',
      },
      {
        id: 'state',
        name: 'Estado',
        type: 'select',
      },
      {
        id: 'evaluatedBy',
        name: 'Valorizado por',
        type: 'text',
      },
    ],
    filtersOptions: [
      { id: 'true', name: 'Finalizado', categoryId: 'state' },
      { id: 'false', name: 'Pendiente', categoryId: 'state' },
    ],
    appliedFilters: [],

    isLoading: false,
    dataIsLoaded: false,
    createdPeriods: [],
  }),

  computed: {
    ...mapState('jobEvaluationProcess', {
      hasFinished: (state) => state.progress.hasFinished,
      hasStarted: (state) => state.progress.hasStarted,
      periods: (state) => state.periods,
      selectedPeriod: (state) => state.selectedPeriod,
    }),

    ...mapState('jobEvaluation', {
      positions: (state) => state.positions,
      evaluations: (state) => state.evaluations,
      areas: (state) => state.areas,
      managements: (state) => state.managements,
    }),

    totalPositions() {
      return this.positions.length;
    },

    positionsEvaluated() {
      return this.positions.reduce((evaluated, pos) => {
        if (pos.evaluation && pos.evaluation.isFinished) return evaluated + 1;
        return evaluated;
      }, 0);
    },
  },

  methods: {
    ...mapActions('jobEvaluation', [
      'getPositions',
      'getAreas',
      'getManagements',
      'getEvaluations',
      'uploadSubfactorExamples',
    ]),

    ...mapActions('jobEvaluationProcess', ['fetchPeriods']),
    ...mapMutations('jobEvaluationProcess', ['setSelectedPeriod']),

    async onPeriodSelect(period) {
      this.createdPeriods = this.createdPeriods.map((item) => ({
        ...item,
        active: item.id === period.id,
      }));
      this.setSelectedPeriod(period);
      await this.getEvaluations();
    },

    startEvaluation(position) {
      this.isJobEvaluationModalOpen = true;
      this.positionToEvaluate = position;
    },

    closeEvaluation() {
      this.isJobEvaluationModalOpen = false;
      this.positionToEvaluate = {};
    },

    isEvaluated(positionId) {
      return !!this.evaluations.find((ev) => ev.positionId === positionId);
    },

    onFilter(filters) {
      this.appliedFilters = filters;
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      this.dataIsLoaded = false;
      await this.fetchPeriods();
      if (!this.selectedPeriod) this.setSelectedPeriod(this.periods[0]);

      this.createdPeriods = this.periods.map((item) => ({
        ...item,
        active: item.id === this.selectedPeriod.id,
      }));

      await this.getPositions();
      await this.getAreas();
      await this.getManagements();
      await this.getEvaluations();

      const areaOptions = this.areas.map(({ id, name }) => ({
        id,
        name,
        categoryId: 'area',
      }));

      const managementOptions = this.managements.map(({ id, name }) => ({
        id,
        name,
        categoryId: 'management',
      }));

      this.filtersOptions = [...this.filtersOptions, ...areaOptions, ...managementOptions];

      this.dataIsLoaded = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.onPeriodSelect(this.periods[0]);
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.valuation {
  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .filters__section {
    display: flex;
    gap: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
  .content {
    margin-top: 1rem;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    flex-grow: 1;
  }
}
.not-finished {
  height: 100%;
  display: grid;
  place-content: center;
  place-items: center;

  h2 {
    margin: 1em 0 0.5em;
  }
}

.block-icon {
  color: var(--gray-color-400);
  font-size: 4rem;
}

.view {
  flex-grow: 1;
}

.stat {
  display: inline-flex;
  gap: 0.5em;
  align-items: center;
  padding: 0.75em;
  border: 1px solid var(--gray-color-300);
  border-radius: 4px;

  span:first-child {
    color: var(--font-color-300);
    font-size: 0.875rem;
  }

  span:last-child {
    color: var(--font-color-700);
    font-weight: var(--medium);
    font-size: 0.875rem;
  }
}

.action-bar {
  display: flex;
  align-items: center;
  gap: 1em;
}
</style>
