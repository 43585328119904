<template>
  <div class="loading-screen" :class="{ 'full-screen': fullScreen }">
    <LoadingSpinner />
  </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  components: { LoadingSpinner },

  props: {
    fullScreen: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-screen {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: grid;
  place-items: center;
  place-content: center;

  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
  }
}
</style>
