<template>
  <div>
    <Modal size="xlg" ref="modal__jobEvaluation" @close="$emit('close')">
      <template #title> {{ position.name }} </template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit }" class="form__validation">
          <form @submit.prevent="handleSubmit(next)">
            <div class="form__content">
              <div class="subfactor">
                <span>{{ currentFactor.name || '' }}</span>
                <h2>{{ currentSubfactor.name || '' }}</h2>
              </div>
              <p>{{ currentSubfactor.description || '' }}</p>

              <AlertBox type="info">
                Recuerda seleccionar la opci&oacute;n que mejor describa la funci&oacute;n del
                puesto.
              </AlertBox>

              <AlertBox type="error" v-if="showNotFinishedAlert && !areAllSubfactorsEvaluated">
                Se debe seleccionar una opci&oacute;n por cada subfactor para poder terminar la
                evaluaci&oacute;n.
              </AlertBox>

              <div v-if="evaluation" class="options-table">
                <span class="table-header table-cell"></span>
                <span class="table-header table-cell">Orden</span>
                <span class="table-header table-cell">Descripci&oacute;n</span>

                <template v-for="option in currentSubfactor.options">
                  <span
                    :key="`${option.index}-radio`"
                    class="table-cell"
                    :class="{ 'table-cell--gray': option.index % 2 !== 0 }"
                  >
                    <RadioButton
                      :groupName="currentSubfactor.id"
                      :value="{ index: option.index, name: option.name, value: option.value }"
                      :picked="currentSelectedOption"
                      idPropName="index"
                      rules="required"
                      @change="setCurrentSubfactorOption"
                    />
                  </span>
                  <span
                    :key="`${option.index}-index`"
                    class="table-cell"
                    :class="{ 'table-cell--gray': option.index % 2 !== 0 }"
                  >
                    {{ option.index }}
                  </span>
                  <span
                    :key="`${option.index}-name`"
                    class="table-cell"
                    :class="{ 'table-cell--gray': option.index % 2 !== 0 }"
                  >
                    {{ option.name }}
                  </span>
                </template>
              </div>
            </div>

            <div class="modal__buttons">
              <Button
                @click="() => saveProgress()"
                :disabled="!Object.keys(currentSelectedOption).length"
              >
                <unicon name="save" fill="currentColor" />
                Guardar
              </Button>
              <Button v-if="subfactorIndex || factorIndex" @click="previous">
                <unicon name="arrow-left" fill="currentColor" />
                Anterior
              </Button>
              <Button
                type="submit"
                variant="primary"
                :disabled="!Object.keys(currentSelectedOption).length"
              >
                {{ isLastSubfactor ? 'Guardar y Enviar' : 'Siguiente' }}
                <unicon name="arrow-right" fill="currentColor" />
              </Button>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { ValidationObserver } from 'vee-validate';

import AlertBox from '@/components/AlertBox.vue';
import Button from '@/components/buttons/Button.vue';
import Modal from '@/components/Modal.vue';
import RadioButton from '@/components/custom/RadioButton.vue';

export default {
  components: {
    AlertBox,
    Button,
    Modal,
    RadioButton,
    ValidationObserver,
  },

  props: {
    isOpen: Boolean,
    position: Object,
    periodId: String,
  },

  data: () => ({
    factorIndex: 0,
    subfactorIndex: 0,

    evaluation: null,

    showNotFinishedAlert: false,

    evaluationId: null,
  }),

  watch: {
    isOpen(value) {
      if (value) {
        this.loadEvaluation();
        this.open();
      } else {
        this.$emit('close');
        this.$refs.modal__jobEvaluation.close();
      }
    },
  },

  computed: {
    ...mapState('jobEvaluation', {
      factors: (state) => state.factors,
      evaluations: (state) => state.evaluations,
    }),

    currentFactor() {
      return this.factors[this.factorIndex] || {};
    },

    currentSubfactor() {
      if (!this.currentFactor.subfactors) return {};
      return this.currentFactor.subfactors[this.subfactorIndex] || {};
    },

    currentSelectedOption() {
      return this.evaluation[this.factorIndex].subfactors[this.subfactorIndex].selected;
    },

    isLastSubfactor() {
      return (
        this.factorIndex >= 3 && this.subfactorIndex >= this.currentFactor.subfactors.length - 1
      );
    },

    areAllSubfactorsEvaluated() {
      return this.evaluation.every(
        (factor) => factor.subfactors.every((sf) => Object.keys(sf.selected).length),
      );
    },
  },

  methods: {
    ...mapActions('jobEvaluation', ['getFactors', 'getEvaluations', 'saveEvaluation']),

    open() {
      this.$refs.modal__jobEvaluation.open();
    },

    next() {
      if (this.subfactorIndex >= this.currentFactor.subfactors.length - 1) {
        if (this.factorIndex >= 3) {
          this.saveProgress(true);
          return;
        }

        this.factorIndex += 1;
        this.subfactorIndex = 0;
      } else {
        this.subfactorIndex += 1;
      }
    },

    previous() {
      if (this.subfactorIndex <= 0) {
        if (this.factorIndex <= 0) return;

        this.factorIndex -= 1;
        this.subfactorIndex = this.currentFactor.subfactors.length - 1;
      } else {
        this.subfactorIndex -= 1;
      }
    },

    setCurrentSubfactorOption(option) {
      this.evaluation[this.factorIndex].subfactors[this.subfactorIndex].selected = option;
    },

    closeEvaluation() {
      this.factorIndex = 0;
      this.subfactorIndex = 0;
      this.evaluation = null;
      this.evaluationId = null;
      this.showNotFinishedAlert = false;
      this.$emit('close');
      this.$refs.modal__jobEvaluation.close();
    },

    async saveProgress(isFinished = false) {
      if (isFinished && !this.areAllSubfactorsEvaluated) {
        this.showNotFinishedAlert = true;
        return false;
      }

      this.showNotFinishedAlert = false;

      const evaluationWithTotals = this.evaluation.map((factor) => ({
        ...factor,
        value: factor.subfactors.reduce((total, sf) => {
          if (sf.selected.value) return total + sf.selected.value;
          return total;
        }, 0),
      }));

      try {
        await this.saveEvaluation({
          evaluation: evaluationWithTotals,
          total: evaluationWithTotals.reduce((total, factor) => total + factor.value, 0),
          id: this.evaluationId || undefined,
          isFinished,
          inProcess: !isFinished,
          positionId: this.position.id,
        });

        if (isFinished) {
          this.closeEvaluation();
        }

        console.log('Updated evaluation!');
      } catch (error) {
        console.log(error);
      }

      return true;
    },

    async loadEvaluation() {
      try {
        await this.getFactors();

        if (this.position.evaluation) {
          this.evaluation = JSON.parse(JSON.stringify(this.position.evaluation.evaluation));
          this.evaluationId = this.position.evaluation.id;
        } else {
          this.evaluation = this.factors.map((factor) => ({
            id: factor.id,
            name: factor.name,
            subfactors: factor.subfactors.map((sf) => ({
              id: sf.id,
              name: sf.name || '',
              selected: {},
            })),
            value: 0,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.loadEvaluation();
  },
};
</script>

<style lang="scss" scoped>
.form__validation {
  height: 100%;
  form {
    height: 100%;
    display: flex;
    flex-flow: column;
    .form__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1.5rem;
      overflow: auto;
    }
  }
}
.options-table {
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  border: 1px solid var(--gray-color-300);
  border-radius: 6px;
}

.table-cell {
  display: inline-flex;
  align-items: center;
  padding: 1em;
  color: var(--font-color-500);
  font-size: 0.875rem;

  &:not(.table-header):not(:nth-child(3n)) {
    color: var(--font-color-300);
  }

  &--gray:not(.table-header) {
    background-color: var(--gray-color-100);
  }
}

.table-header {
  color: white;
  font-weight: var(--medium);
  background-color: var(--main-color-500);

  &:first-child {
    border-top-left-radius: 6px;
  }

  &:nth-child(3) {
    border-top-right-radius: 6px;
  }
}

.subfactor {
  border-bottom: 1px solid var(--gray-color-300);

  span {
    margin-bottom: 0.5em;
    color: var(--font-color-300);
    font-size: 0.75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  h2 {
    padding-bottom: 0.3em;
  }

  p {
    line-height: 140%;
  }
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;

  .button:first-child {
    margin-right: auto;
  }
}
</style>
