<template>
  <div class="container">
    <table class="table">
      <tr class="">
        <th>#</th>
        <th>Puesto</th>
        <th>Niveles</th>
        <th>Gerencia</th>
        <th>Área</th>
        <th>Puntos</th>
        <th>Estado</th>
        <th>Valuar</th>
        <th>Valorizado por</th>
      </tr>

      <EvaluationTableRow
        v-for="(position, index) in filteredPositions"
        :key="position.id"
        :index="index"
        :position="position"
        @startEvaluation="$emit('startEvaluation', $event)"
        @viewEvaluationReport="$emit('viewEvaluationReport', $event)"
      />
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import EvaluationTableRow from './EvaluationTableRow.vue';

export default {
  components: { EvaluationTableRow },
  data() {
    return {
      filteredPositions: [],
    };
  },
  props: {
    filters: Array,
  },

  methods: {
    filterPositions() {
      if (!this.filters.length) this.filteredPositions = [...this.positions];

      this.filteredPositions = this.positions.filter((pos) => {
        let isValid = true;
        this.filters.forEach((filter) => {
          const options = filter.options.filter(({ active }) => active).map(({ id }) => id);
          if (options.length) {
            if (filter.id === 'state') {
              isValid = isValid
              && (pos.evaluation.isFinished ? options.includes('true') : options.includes('false'));
            } else if (filter.id === 'evaluatedBy') {
              isValid = isValid && pos.evaluation.evaluatedBy.includes(filter.searchValue);
            } else isValid = isValid && options.includes(pos[filter.id]);
          }
        });
        return isValid;
      });
    },
  },

  watch: {
    filters: {
      handler() {
        this.filterPositions();
      },
      deep: true,
    },
    positions: {
      handler() {
        this.filterPositions();
      },
      deep: true,
    },
  },

  mounted() {
    this.filterPositions();
  },

  computed: {
    ...mapState('jobEvaluation', {
      positions: (state) => state.positions.map((position) => ({
        ...position,
        area: position.parentId,
        management: position.parentsId.filter((id) => id !== position.parentId)[0],
      })),
      areas: (state) => state.areas,
      managements: (state) => state.managements,
    }),
  },
};
</script>

<style scoped>
.container {
  flex-grow: 1;
}
table {
  border-bottom-left-radius: 5px !important;
  width: 100%;
}
</style>
