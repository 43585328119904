<template>
  <SideModal :isOpen="isOpen && !!position" @close="closeReport" size="md">
    <template #title>Puntos</template>
    <template #content>
      <div class="evaluation-report">
        <div class="evaluation-report-header">
          <h4>Reporte {{ position.name }}</h4>
          <Menu direction="left">
            <template #label>
              <unicon name="ellipsis-v" fill="currentColor" height="20px" width="20px" />
            </template>
            <template #options>
              <MenuItem @click="exportToExcel">
                <unicon name="file-download" fill="currentColor" height="20px" width="20px" />
                Exportar a Excel
              </MenuItem>
            </template>
          </Menu>

          <span class="evaluation-metadata">{{
            `Valorizado por ${position.evaluation.evaluatedBy} el ${formattedDate}`
          }}</span>
        </div>

        <div class="evaluation-report-table">
          <template v-for="factor in position.evaluation.evaluation">
            <span :key="`name-${factor.id}`" class="evaluation-report-table-cell header-cell">{{
              factor.name
            }}</span>
            <span :key="`value-${factor.id}`" class="evaluation-report-table-cell header-cell">{{
              factor.value || 0
            }}</span>

            <template v-for="subfactor in factor.subfactors">
              <span :key="`name-${subfactor.id}`" class="evaluation-report-table-cell">{{
                subfactor.name
              }}</span>
              <span :key="`value-${subfactor.id}`" class="evaluation-report-table-cell">{{
                subfactor.selected.value || 0
              }}</span>
            </template>
          </template>

          <span class="evaluation-report-table-cell">Valor del Puesto</span>
          <span class="evaluation-report-table-cell">{{ position.evaluation.total }}</span>
        </div>
      </div>
    </template>
  </SideModal>
</template>

<script>
import { utils, writeFile } from 'xlsx';

import SideModal from '@/components/menu/SideModal.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';

export default {
  components: {
    Menu,
    MenuItem,
    SideModal,
  },

  data: () => ({
    isOpen: false,
    position: null,
  }),

  computed: {
    formattedDate() {
      if (this.position.evaluation && this.position.evaluation.dateEvaluated) {
        const rawDate = this.position.evaluation.dateEvaluated.toDate();

        const day = rawDate.getDate();
        const month = rawDate.getMonth();
        const year = rawDate.getFullYear();
        return `${day}/${month}/${year}`;
      }

      return '';
    },
  },

  methods: {
    viewEvaluationReport(position) {
      if (!position.evaluation) return;
      this.position = position;
      this.isOpen = true;
    },

    closeReport() {
      this.position = null;
      this.isOpen = false;
    },

    exportToExcel() {
      const evaluationMap = this.position.evaluation.evaluation.reduce((acc, factor, index) => {
        const factorArray = [
          { 'Nro.': index + 1, 'Factor/Subfactor': factor.name, Valor: factor.value },
        ];

        factor.subfactors.forEach((sf) => {
          factorArray.push({
            'Nro.': '',
            'Factor/Subfactor': sf.name,
            Valor: sf.selected.value || 0,
          });
        });

        return [...acc, ...factorArray];
      }, []);

      evaluationMap.push({
        'Factor/Subfactor': 'Valor del Puesto',
        Valor: this.position.evaluation.total,
      });

      const worksheet = utils.json_to_sheet(evaluationMap);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, this.position.name);
      writeFile(workbook, 'reporte_puesto.xlsx');
    },
  },
};
</script>

<style lang="scss" scoped>
.evaluation-report {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: white;
  overflow-y: hidden;
  z-index: var(--index-2);
}

.evaluation-report-header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: 1em;
  row-gap: 0.25em;

  .menu {
    grid-column-start: 2;
    grid-row: 1 / -1;
  }
}

.evaluation-metadata {
  font-size: 0.875rem;
  color: var(--font-color-300);
}

.evaluation-report-table {
  position: relative;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr auto;
  border: 1px solid var(--gray-color-300);
  border-radius: 6px;
  overflow: auto;
}

.evaluation-report-table-cell {
  display: inline-flex;
  align-items: center;
  padding: 1.125em;
  font-size: 0.875rem;
  font-weight: var(--medium);
  color: var(--font-color-300);

  &.header-cell {
    color: var(--font-color-600);
    font-weight: var(--semi-bold);
    background-color: var(--gray-color-200);
  }

  &:last-child,
  &:nth-last-child(2) {
    position: sticky;
    bottom: 0;
    color: var(--font-color-600);
    background-color: white;
    border-top: 1px solid var(--gray-color-300);
  }

  &:nth-child(even) {
    justify-content: flex-end;
  }

  &:last-child {
    font-size: 1.125rem;
  }
}
</style>
