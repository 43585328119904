<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ position.name }}</td>
    <td>{{ 1 }}</td>
    <td>{{ (management && management.name) || '-' }}</td>
    <td>{{ (area && area.name) || '-' }}</td>
    <td>
      <div class="flex">
        {{ (position.evaluation && position.evaluation.total) || '-' }}
        <button
          class="info-button"
          title="Reporte"
          :disabled="!position.evaluation"
          @click="viewEvaluationReport"
        >
          <unicon
            name="info-circle"
            fill="currentColor"
            width="16px"
            height="16px"
          />
        </button>
      </div>
    </td>
    <td>
      <span class="tag" :class="tagClass">
        {{ isFinished ? 'Finalizado' : inProcess ? 'En proceso' : 'Pendiente' }}
      </span>
    </td>
    <td>
      <button
        class="evaluate-button"
        title="Iniciar evaluación"
        @click="startEvaluation"
      >
        <unicon name="file-edit-alt" fill="currentColor" />
      </button>
    </td>
    <td>
      <UserSnippet
        v-if="position.evaluation"
        :name="position.evaluation.evaluatedBy"
        :date="position.evaluation.dateEvaluated && position.evaluation.dateEvaluated.toDate()"
      />
      <span v-else>-</span>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex';

import UserSnippet from '@/components/UserSnippet.vue';

export default {
  components: { UserSnippet },
  props: {
    index: {
      required: true,
      type: Number,
    },
    position: {
      required: true,
      type: Object,
    },
  },

  computed: {
    ...mapState('jobEvaluation', {
      areas: (state) => state.areas,
      managements: (state) => state.managements,
    }),

    isFinished() {
      if (this.position && this.position.evaluation) {
        return this.position.evaluation.isFinished;
      }

      return false;
    },

    inProcess() {
      if (this.position && this.position.evaluation) {
        return this.position.evaluation.inProcess;
      }
      return false;
    },

    area() {
      return this.areas.find((a) => a.id === this.position.parentId);
    },

    management() {
      if (!this.area) return null;
      return this.managements.find((m) => m.id === this.area.parentId);
    },

    tagClass() {
      if (this.isFinished) return 'tag--success';
      if (this.inProcess) return '';
      return 'tag--warning';
    },
  },

  methods: {
    startEvaluation() {
      this.$emit('startEvaluation', this.position);
    },

    viewEvaluationReport() {
      if (!this.position.evaluation) return;

      this.$emit('viewEvaluationReport', this.position);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  padding: 0.375rem;
  font-size: 0.75rem;
  font-weight: var(--medium);
  color: var(--font-color-500);
  background-color: var(--gray-color-200);
  border-radius: 4px;

  &--success {
    color: var(--success-color-600);
    background-color: var(--success-color-100);
  }

  &--warning {
    color: var(--warning-color-600);
    background-color: var(--warning-color-200);
  }
}

.evaluate-button {
  color: var(--main-color-500);
  transition: color 300ms;

  &:hover {
    color: var(--main-color-600);
  }
}

.info-button {
  color: var(--font-color-100);
  transition: color 150ms;

  &:not(:disabled):hover {
    color: var(--font-color-500);
  }

  &:disabled {
    color: var(--gray-color-500);
    cursor: not-allowed;
  }
}

.flex {
  gap: 0.5em;
}

tr:last-child {
  td:first-child {
    border-bottom-left-radius: 5px;
  }

  td:last-child {
    border-bottom-right-radius: 5px;
  }
}
</style>
