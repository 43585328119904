<template>
  <div class="user-snippet">
    <span>{{ initials }}</span>
    <span :title="name">{{ name }}</span>
    <span>{{ formattedDate }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    date: {
      required: true,
      type: Date,
    },
  },

  computed: {
    initials() {
      if (!this.name) return '';
      const nameArray = this.name.split(' ');
      const initialsArray = nameArray.map((word) => word[0]);
      return `${initialsArray[0].toUpperCase()}${(
        initialsArray[1] || this.name[1]
      ).toUpperCase()}`;
    },

    formattedDate() {
      if (!this.date) return '';
      const day = this.date.getDate();
      const month = this.date.getMonth();
      const year = this.date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-snippet {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  align-items: center;
  column-gap: 0.5em;
  row-gap: 0.125em;
  font-size: 0.875rem;

  span:first-child {
    width: 30px;
    height: 30px;
    display: inline-flex;
    place-items: center;
    place-content: center;
    grid-row: 1 / -1;
    color: var(--main-color-500);
    font-weight: var(--medium);
    background-color: var(--main-color-100);
    border-radius: 50%;
  }

  span:nth-child(2) {
    color: var(--font-color-600);
    font-weight: var(--medium);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span:last-child {
    color: var(--font-color-200);
    font-size: 0.75rem;
  }
}
</style>
